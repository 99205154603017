import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {goBack, closeModal} from "./js/store/router/actions";
import {getActivePanel} from "./js/services/_functions";
//import * as VK from './js/services/VK';

import {View, Root, ConfigProvider} from "@vkontakte/vkui";

import HomePanelIntro from './js/panels/intro/base';

import HomePanelProfile from './js/panels/home/base';
import HomePanelForm from './js/panels/home/form';
import HomePanelProlog from './js/panels/home/prolog';



import HomePanelBaseFirstBlock from './js/panels/first/base_first_block';
import FirstPanelR1 from './js/panels/first/r1';
import FirstPanelR2 from './js/panels/first/r2';
import FirstPanelR3 from './js/panels/first/r3';
import FirstPanelR4 from './js/panels/first/r4';
import FirstPanelR5 from './js/panels/first/r5';
import FirstPanelR6 from './js/panels/first/r6';
import FirstPanelR7 from './js/panels/first/r7';
import FirstPanelR8 from './js/panels/first/r8';
import FirstPanelR9 from './js/panels/first/r9';
import FirstPanelR10 from './js/panels/first/r10';
import FirstPanelR11 from './js/panels/first/r11';
import FirstPanelR12 from './js/panels/first/r12';
import FirstPanelR13 from './js/panels/first/r13';


//import HomePanelBaseSecondBlock from './js/panels/second/base_second_block';
//import SecondPanelR1 from './js/panels/second/r1';
//import SecondPanelR2 from './js/panels/second/r2';
//import SecondPanelR3 from './js/panels/second/r3';
//import SecondPanelR4 from './js/panels/second/r4';
//import SecondPanelR5 from './js/panels/second/r5';
//import SecondPanelR6 from './js/panels/second/r6';

//import HomePanelBaseThirdBlock from './js/panels/third/base_third_block';
//import ThirdPanelR1 from './js/panels/third/r1';
//import ThirdPanelR2 from './js/panels/third/r2';
//import ThirdPanelR3 from './js/panels/third/r3';
//import ThirdPanelR4 from './js/panels/third/r4';
//import ThirdPanelR5 from './js/panels/third/r5';
//import ThirdPanelR6 from './js/panels/third/r6';
//import ThirdPanelR7 from './js/panels/third/r7';

//import HomeBotsListModal from './js/components/modals/HomeBotsListModal';
//import HomeBotInfoModal from './js/components/modals/HomeBotInfoModal';

//import MorePanelExample from './js/panels/more/example';
//TODO: add first screen; make something with redux
//all about GAPI
//https://docs.google.com/spreadsheets/d/1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U/edit#gid=0 - our table
//1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U - spreadsheetID
//ya29.Il-_B4b4tclK0IQfKq87Yqc26wp3vrLJCWmP8L-hmcnwsBMR7yXjjZ43vD1MJAf2IgPEy7gycItHe00_9btNWbH_3wRMt98DJN2lVr_DoyNQ1-XJvjNG-0TS21vLkWvBYA  -- access token gor PG


//const SHEET_ID = '1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U';
//const ACCESS_TOKEN = 'ya29.Il-_B4b4tclK0IQfKq87Yqc26wp3vrLJCWmP8L-hmcnwsBMR7yXjjZ43vD1MJAf2IgPEy7gycItHe00_9btNWbH_3wRMt98DJN2lVr_DoyNQ1-XJvjNG-0TS21vLkWvBYA';
var ACCESS_TOKEN = 'ya29.a0Adw1xeVaxhCOmRIExLzdNxHwFQDyXso1G16BXX0UtHNfZtaHahmEGNOhSw2WY_68TChXFVEBZ5htMIJZg9RZ-QlBp4Fx3fXqP3sIfivo9EJFnRluhyFA9oQmIxm9YIpsiFpGx-btiNgRoa10c0ltbZElP1oz-Li36as';
export {ACCESS_TOKEN};
//const refresh_token = '1//043QUhnEiJFLICgYIARAAGAQSNwF-L9Ir_ShhWrwH5qO9pOVZOQEA5FZSsQ6bEm55mwFSb-Q15MXj9E86kK6GDaljzDpGPWrAhuE';
//const client_id = '45826356530-fcnhlcetibkv2eb80fvl5tmjko88vn18.apps.googleusercontent.com';
//const client_secret = 'eWL0oYDXkMCMi4audRw_kCpW';


class App extends React.Component {
    constructor(props) {
        super(props);

        this.lastAndroidBackAction = 0;
    }

    componentDidMount() {
      console.log('app started');
        const {goBack} = this.props;

    //    dispatch(VK.initApp());

        window.onpopstate = () => {
            let timeNow = +new Date();

            if (timeNow - this.lastAndroidBackAction > 500) {
                this.lastAndroidBackAction = timeNow;

                goBack();
            } else {
                window.history.pushState(null, null);
            }
        };

  //      const refresh_url = "https://www.googleapis.com/oauth2/v4/token";

//        const post_body = `grant_type=refresh_token&client_id=${encodeURIComponent(client_id)}&client_secret=${encodeURIComponent(client_secret)}&refresh_token=${encodeURIComponent(refresh_token)}`;

//        let refresh_request = {
//            body: post_body,
//            method: "POST",
//            headers: new Headers({
//                'Content-Type': 'application/x-www-form-urlencoded'
//            })
//        }

        // post to the refresh endpoint, parse the json response and use the access token to call files.list
//        fetch(refresh_url, refresh_request).then( response => {
//                return(response.json());
//            }).then( response_json =>  {
      //          console.log(response_json);
        //        ACCESS_TOKEN = response_json.access_token;
//        });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {activeView, activeStory, activePanel, scrollPosition} = this.props;

        if (
            prevProps.activeView !== activeView ||
            prevProps.activePanel !== activePanel ||
            prevProps.activeStory !== activeStory
        ) {
            let pageScrollPosition = scrollPosition[activeStory + "_" + activeView + "_" + activePanel] || 0;

            window.scroll(0, pageScrollPosition);
        }
    }
//there should be goBack props in const block
    render() {
        const { popouts, activeView, panelsHistory, colorScheme} = this.props;

        let history = (panelsHistory[activeView] === undefined) ? [activeView] : panelsHistory[activeView];
        let popout = (popouts[activeView] === undefined) ? null : popouts[activeView];
    //    let activeModal = (activeModals[activeView] === undefined) ? null : activeModals[activeView];

    //    const homeModals = (
    //        <ModalRoot activeModal={activeModal}>
    /////            <HomeBotsListModal
    //                id="MODAL_PAGE_BOTS_LIST"
    //                onClose={() => closeModal()}
    //            />
    //            <HomeBotInfoModal
    //                id="MODAL_PAGE_BOT_INFO"
    //                onClose={() => closeModal()}
    //            />
    //        </ModalRoot>
      //  );

        return (
            <ConfigProvider isWebView={true} scheme={colorScheme}>
                <Root activeView={activeView} popout={popout}>
                    <View
                        id="home"
                //        modal={homeModals}
                        activePanel={getActivePanel("home")}
                        history={history}
                    >
                        <HomePanelProfile id="base" />
                        <HomePanelForm id="form"/>
                        <HomePanelProlog id="prolog"/>

                    </View>
                    <View
                        id="intro"
                  //      modal={homeModals}
                        activePanel={getActivePanel("intro")}
                        history={history}
                    >
                        <HomePanelIntro id="base" />


                    </View>

                    <View
                        id="first_block"
                        //modal={homeModals}
                        activePanel={getActivePanel("first_block")}
                        history={history}
                    >
                        <HomePanelBaseFirstBlock id="base_first_block" />
                        <FirstPanelR1 id="r1"/>
                        <FirstPanelR2 id="r2"/>
                        <FirstPanelR3 id="r3"/>
                        <FirstPanelR4 id="r4"/>
                        <FirstPanelR5 id="r5"/>
                        <FirstPanelR6 id="r6"/>
                        <FirstPanelR7 id="r7"/>
                        <FirstPanelR8 id="r8"/>
                        <FirstPanelR9 id="r9"/>
                        <FirstPanelR10 id="r10"/>
                        <FirstPanelR11 id="r11"/>
                        <FirstPanelR12 id="r12"/>
                        <FirstPanelR13 id="r13"/>
                    </View>
                    <View
                        id="modal"

                        activePanel={getActivePanel("modal")}
                        history={history}
                    >

                    </View>
                </Root>
            </ConfigProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeView: state.router.activeView,
        activeStory: state.router.activeStory,
        panelsHistory: state.router.panelsHistory,
        activeModals: state.router.activeModals,
        popouts: state.router.popouts,
        scrollPosition: state.router.scrollPosition,

        colorScheme: state.vkui.colorScheme
    };
};


function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({goBack, closeModal}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
