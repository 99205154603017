import React from 'react';
//import YouTube from 'react-youtube';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from "redux";
import Icon16Clear from '@vkontakte/icons/dist/16/clear';
//import {
//  Link
//} from '@vkontakte/vkui';
//import VKconnect from '@vkontakte/vk-connect';
//import { gapi } from 'gapi-script';
import {
  Input,
  //Div,
  Snackbar,
  Avatar,
  PanelSpinner,
  Panel,
  //Group,
  Button,
  PanelHeader,
//  PanelHeaderBack,
  //Header,
  FormLayout,
  //FormLayoutGroup, Select,
  Textarea,
   //Checkbox
} from "@vkontakte/vkui";
import {
  goBack,
  openPopout,
  closePopout,
  openModal, setPage
} from "../../store/router/actions";
//import * as VK from '../../services/VK';
//import img from './1.jpg';
import './Persik.css'

//import {
//  ACCESS_TOKEN
//} from '../../../App.js';
//import {
//  SHEET_ID
//} from './base.js';
//var x = 0;
//var today = new Date();
//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();




const orangeBackground = {
  backgroundImage: 'linear-gradient(135deg, #ffb73d, #ffa000)'
};
//101064757234545608916
///0KCv_KD9POo5rIqnRjTdOjk1 client secret
//  const SPREADSHEET_ID = '1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U'; //+++from the URL of your blank Google Sheet
//  const CLIENT_ID = '45826356530-fcnhlcetibkv2eb80fvl5tmjko88vn18.apps.googleusercontent.com';//'671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com';// '671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com'; //+++from https://console.developers.google.com/apis/credentials
//  const API_KEY = 'AIzaSyAA1d2jaIB0wsxmPKo7qQ-GjbK1z7a1Qno';// 'AIzaSyDhMYGbNPaNO9VW9LlLlUQFP38k5rvPOds'; //+++https://console.developers.google.com/apis/credentials
//AIzaSyCt1WKxstmty-Y72dO7QF-5OyES87khvpM
//
//  const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';


class HomePanelForm extends React.Component {


    constructor(props) {
      super(props);

      this.state = {
        value: '',
        value2: '',
        show1: false,
        show2: false,
        show3: false,
        fetchedUser: false,
        geoData: null,
        showError: false,
        snackbar: null,
        com_name: ' ',
      planet: null,
      loading: true,
      cb1: false,
      cb2: false,
      cb3: false,
      cb4: false,
      cb5: false,
      cb6: false,
      text1: ' ',
      text2: ' '
      };
      this.openBase = this.openBase.bind(this);
      this.sleep = this.sleep.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.addressItems = [
  { label: 'Почтовый индекс', name: 'zip' },
  { label: 'Страна', name: 'country' },
  { label: 'Город', name: 'city' }
];

this.onChange = this.onChange.bind(this);
      //    this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
    }
    onChange(e) {
   const { name, value } = e.currentTarget;
   this.setState({ [name]: value });
 }

 sleep (time) {
   return new Promise((resolve) => setTimeout(resolve, time));
 }

    handleChange(event) {
      this.setState({
        value: event.target.value
      });
      //let x = this.state.value;
      //console.log(this.state.value);

    }

    TestAnswer1 = () => {




      fetch(`https://spacequest.site:82/form_put/${global.team_rn}/${this.state.com_name}/${this.state.planet}/${this.state.cb1}/${this.state.cb2}/${this.state.cb3}/${this.state.cb4}/${this.state.cb5}/${this.state.cb6}/${this.state.text1}/${this.state.text2}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          goBack();

          //this.setState({
          //            com_name:  data.message
          //});
        });

    };

    TestAnswer2 = () => {
      if ((this.state.value.toUpperCase() === 'катюша'.toUpperCase()) && this.state.show1) {
        this.setState({
          show2: true,
          value: ''
        });
      } else {
        this.openBase()
      }
    };

    handleSubmit(event) {
      if (this.state.value === 'келдыш') {
        this.setState({
          show: true
        });
      }

      //alert('A name was submitted: ' + this.state.value);
      event.preventDefault();
    }
    //    state = {
    //      show: false,
    //  riddle: '',
    //        groups: {
    //            admined: [],
    //            other: [],
    //        },
    //        loading: true,
    //        errorGetAuthToken: false
    //    };


    testGeo = () => {
      console.log('geo is taken')


    /*  VKconnect
        .send('VKWebAppGetGeodata')
        .then(data => {
          // Handling received data
          console.log(data);
          this.setState({
            geoData: data,
            show3: true
          });
          console.log(this.state.geoData);
          //console.log(this.state.fetchedUser.last_name);
        })
        .catch(error => {
          this.setState({
            showError: true
          });
          console.log(error);
          // Handling an error
        }); //this.setState({fetchedUser: VK.getUserInfo().data})
      this.setState({
        showError: true
      });
      */
      this.setState({
        showError: true
      });
    };


//form/${this.state.fetchedUser.id}/${this.state.com_name}/${this.state.planet}/${this.state.cb1}/${this.state.cb2}/${this.state.cb3}/${this.state.cb4}/${this.state.cb5}/${this.state.cb6}/${this.state.text1}/${this.state.text2}




 componentDidMount() {


          fetch(`https://spacequest.site:82/form_get/${global.team_rn}/${this.state.com_name}/${this.state.planet}/${this.state.cb1}/${this.state.cb2}/${this.state.cb3}/${this.state.cb4}/${this.state.cb5}/${this.state.cb6}/${this.state.text1}/${this.state.text2}/`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }})
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              console.log(data + global.team_rn);
              if ( data.status === 'change'){
                this.setState({
                com_name: data.com_name.replace(/[+]/g," "),
                planet: data.planet,

                cb1: !(data.cb1==="false"),
                cb2: !(data.cb2==="false"),
                cb3: !(data.cb3==="false"),
                cb4: !(data.cb4==="false"),
                cb5: !(data.cb5==="false"),
                cb6: !(data.cb6==="false"),
                text1: data.text1.replace(/[+]/g," "),
                text2: data.text2.replace(/[+]/g," ")
                });
              }
              this.setState({
                loading: false
              });
              console.log("changed");
              console.log(data);
              //this.setState({
              //            com_name:  data.message
              //});
            });



      //this.setState({fetchedUser: VK.getUserInfo().data})
      //  this.openBase()
      //  this.handleClientLoad();
      //console.log(ACCESS_TOKEN);
      //  this.updateSheetValues();
      //console.log(this.props)
    }


    updateSheetValues = () => {
    };


    openBase() {
      if (this.state.snackbar) return;
      this.setState({
        snackbar:
          <
          Snackbar
        layout = "vertical"
        onClose = {
          () => this.setState({
            snackbar: null
          })
        }
        before = {
          <
          Avatar size = {
            24
          }
          style = {
            orangeBackground
          } > < Icon16Clear fill = "#fff"
          width = {
            14
          }
          height = {
            14
          }
          /></Avatar >
        } >
        Ответ неверный <
        /Snackbar>
      });
    }





    componentDidUpdate(prevProps, prevState, snapshot) {
      //if (this.state.riddle.equals('asd')) {this.setState({show: true})}

      //      console.log(this.state.riddle)
    }

    handleInput = (e) => {
      let value = e.currentTarget.value;

      if (e.currentTarget.type === 'checkbox') {
        value = e.currentTarget.checked;
      }

      this.setState({
        inputData: {
          ...this.state.inputData,
          [e.currentTarget.name]: value
        }
      })
    };
    //  {this.state.loading && <PanelSpinner/>}
    //  {!this.state.loading && this.state.errorGetAuthToken && <Group>
    //      <Div>Возникла ошибка при получении данных, возможно Вы не выдали права на список групп.</Div>
    //      <Div>
    //          <Button size="l" stretched={true} onClick={() => this.getAuthToken()}>Запросить
    //              повторно</Button>
    //      </Div>
    //  </Group>}
    render() {
       const { com_name, text1, text2 } = this.state;
      const {
        id,
  //      goBack,
        setPage
      } = this.props;


      return ( <
        Panel id = {
          id
        } >
        <
        PanelHeader  >
          Команда {global.team_rn}  <
          /PanelHeader>
{this.state.loading && <PanelSpinner/>}

{!this.state.loading &&

          <FormLayout>
                <Input
                        top="Название команды"
                        name="com_name"
                        value={com_name}
                        onChange={this.onChange}
                        status={com_name.length>3 ? 'valid' : 'error'}
                        autoComplete = "off"
                        bottom={com_name.length>3 ? 'Название команды подходит!' : ''}
                      />

                       {/*<Select
                        top="С какой ты планеты?"
                        placeholder="Выбери планету"
                        onChange={this.onChange}
                        value={planet}
                        status={planet ? 'valid' : 'error'}
                        name="planet"
                      >
                        <option value="0">Меркурий</option>
                        <option value="1">Венера</option>
                        <option value="2">Земля</option>
                        <option value="3">Марс</option>
                        <option value="4">Юпитер</option>
                        <option value="5">Сатурн</option>
                        <option value="6">Уран</option>
                        <option value="7">Нептун</option>
                      </Select>

                      <FormLayoutGroup top="Почему тебе интересен космос?">
                        <Checkbox onChange={this.onChange}  name="cb1" defaultChecked={cb1}>Космос - это что-то необъятное и бесконечное</Checkbox>
                        <Checkbox onChange={this.onChange}  name="cb2" defaultChecked={cb2}>Космос - возможность добыть ресурсы и стать богаче</Checkbox>
                        <Checkbox onChange={this.onChange}   name="cb3" defaultChecked={cb3}>Космос - наш способ переселиться с Земли куда-то дальше</Checkbox>
                      </FormLayoutGroup>

                      <FormLayoutGroup top="Как думаешь, что из этого больше похоже на космическое будущее?">
                        <Checkbox onChange={this.onChange}   name="cb4" defaultChecked={cb4}>Роботы правят миром</Checkbox>
                        <Checkbox onChange={this.onChange}   name="cb5" defaultChecked={cb5}>Мы управляем всем дистанционно</Checkbox>
                        <Checkbox onChange={this.onChange}   name="cb6" defaultChecked={cb6}>Мы живем на других планетах</Checkbox>
                      </FormLayoutGroup>*/}

                      <Textarea name="text1"
                      value={text1}
                      onChange={this.onChange} top="Имена членов команды - чтобы мы могли выписать красивые грамоты о прохождении" />
                      <Textarea name="text2"
                      value={text2}
                      onChange={this.onChange} top="Любые пожелания!" />


                 <Button size="xl" onClick = {
            () => {this.TestAnswer1(); setPage('first_block','base_first_block');}}>В игру</Button>

                    </FormLayout>}





           <
          /Panel>
        );
      }
      _onReady(event) {
        // access to player in all event handlers via event.target
        //  event.target.pauseVideo();
      }
    }

//setPage('intro','base')
    function mapDispatchToProps(dispatch) {
      return {
        dispatch,
        ...bindActionCreators({
          goBack,
          openPopout,
          closePopout,
          openModal,
          setPage
        }, dispatch)
      }
    }

    const mapStateToProps = (state) => {
      console.log(state)
      return {
        show1: state.show1,
        show2: state.show2,

        accessToken: state.vkui.accessToken
      };
    };

    export default connect(mapStateToProps, mapDispatchToProps)(HomePanelForm);
