import React from 'react';
import {
  connect
} from 'react-redux';
//import VKconnect from '@vkontakte/vk-connect';

import {
  closePopout,
  goBack,
  openModal,
  openPopout,
  setPage
} from '../../store/router/actions';

//import Telegram from 'telegram-send-message';

import {
  Div,
  Panel,
  Alert,
  Group,
  Button,
  PanelHeader,
//  PanelHeaderBack,
  PanelSpinner,
  FormLayoutGroup
} from "@vkontakte/vkui"
import logo from './logo.png';
//import {
//  ACCESS_TOKEN
//} from '../../../App.js';
//const SHEET_ID = '1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U';
//const { TelegramClient } = require('messaging-api-telegram');
//const client = TelegramClient.connect('1058147643:AAH8rcL7G9GRkvy2CbFfmEdWCSIFNehvZ6U');
//export {
//  SHEET_ID
//};

//const { sendMessageFor } = require('simple-telegram-message')
//const sendMessage = sendMessageFor('1058147643:AAH8rcL7G9GRkvy2CbFfmEdWCSIFNehvZ6U', '3191519')


//671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com client idea
//0KCv_KD9POo5rIqnRjTdOjk1 client secret
//AIzaSyDhMYGbNPaNO9VW9LlLlUQFP38k5rvPOds api key
//'ya29.a0Adw1xeVRAWcqYURYhWXW9nafHRayv-t_hXS1q-zxfeQjExazbHxBlu_LO6O0MUx12ov9d_gSKXEgcn6lOP8CjKHKQQ1S-bN6MRbl4AFO60xnjzIDdqmcNlW8uhN6__hTcn_QHrRzkao74W1C_diZ7nL5pat_3MuTp66H';
//const { TelegramClient } = require('messaging-api-telegram');

// get accessToken from telegram [@BotFather](https://telegram.me/BotFather)
//const client = TelegramClient.connect('1058147643:AAH8rcL7G9GRkvy2CbFfmEdWCSIFNehvZ6U');

//v/ar x = 0;
//var today = new Date();//'rogachevav'
//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
//import * as VK from '../../../js/services/VK';
class HomePanelBaseFirstBlock extends React.Component {

    state = {
      r1: 0,
      r2: 0,
      r3: 0,
      r4: 0,
      r5: 0,
      r6: 0,
      r7: 0,
      r8: 0,
      r9: 0,
      r10: 0,
      r11: 0,
      r12: 0,
      r13: 0,
      showPolet: false,
      showCol: false,
      fetchedUser: null,
      geoData: null,
      result1: 0,
      loading: true
    };

    takeGeo = () => {
      console.log(global.team_rn)

console.log(this.state)
  //    this.updateSheetValues();
    };

    Peresdacha = () => {
      /*console.log(global.team_rn)
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r1/no`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r2/no`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r3/no`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r4/no`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r5/no`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
    console.log(this.state)
    this.setState({
    result: 0
    });
    //    this.updateSheetValues();
    */
    };


    updateSheetValues = () => {
      var res = 0;
      fetch(`https://spacequest.site:82/get_marks/${global.team_rn}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //if
          console.log(data.user_marks.r3);
res = parseInt(data.user_marks.r1.replace("no","0")) + parseInt(data.user_marks.r2.replace("no","0")) + parseInt(data.user_marks.r3.replace("no","0")) + parseInt(data.user_marks.r4.replace("no","0")) + parseInt(data.user_marks.r5.replace("no","0"));
console.log(res)
         //всего 28 баллов?


              this.setState({
                r1: parseInt(data.user_marks.r1.replace("no","0")),
                r2: parseInt(data.user_marks.r2.replace("no","0")),
                r3: parseInt(data.user_marks.r3.replace("no","0")),
                r4: parseInt(data.user_marks.r4.replace("no","0")),
                r5: parseInt(data.user_marks.r5.replace("no","0")),
                r6: parseInt(data.user_marks.r6.replace("no","0")),
                r7: parseInt(data.user_marks.r7.replace("no","0")),
                r8: parseInt(data.user_marks.r8.replace("no","0")),
                r9: parseInt(data.user_marks.r9.replace("no","0")),
                r10: parseInt(data.user_marks.r10.replace("no","0")),
                r11: parseInt(data.user_marks.r11.replace("no","0")),
                r12: parseInt(data.user_marks.r12.replace("no","0")),
                r13: parseInt(data.user_marks.r13.replace("no","0")),
              result: res,
              showCol: true,
              loading: false
              });


          console.log(data);

        })

    }




    //      {this.state.geoData &&
    //      <Group title="Результат из VK Connect">
    //        <Div
    //        //	before={this.state.fetchedUser.photo_200 ? <Avatar src={this.state.fetchedUser.photo_200}/> : null}
    //        >
    //          {`${this.state.fetchedUser.first_name} ${this.state.fetchedUser.last_name}`}
    //      работает фетчед юзер/
    //
    //        </Div>
    //      </Group>}





    openPopoutHelp() {
      this.props.openPopout( <
        Alert actions = {
          [{
            title: 'Хорошо',
            autoclose: true
          }]
        }
        onClose = {
          () => this.props.closePopout()
        } >

        <
        p >Код вашей команды {global.team_rn}, по нему можно зайти всем членам команды - прогресс общий!< /p> < /
        Alert >
      );
    }

    openPopoutSecond() {
      this.props.openPopout( <
        Alert actions = {
          [{
            title: 'Понятно',
            autoclose: true,
            action: this.takeGeo
          },
          {
            title: 'Пересдача',
            autoclose: true,
            action: this.Peresdacha
          }
        ]
        }
        onClose = {
          () => this.props.closePopout()
        } >

        <
        p >{this.state.result === 0 ? `Сначала пройдите подготовку к полёту` : `Упс, вы набрали за подготовку очень мало баллов - всего ${this.state.result} из 28! НА ПЕРЕСДАЧУ!`}< /p> < /
        Alert >
      );
    }

    openPopoutThird() {
      this.props.openPopout( <
        Alert actions = {
          [{
            title: 'Хорошо',
            autoclose: true,
          }]
        }
        onClose = {
          () => this.props.closePopout()
        } >

        <
        p >Места открываются по очереди< /p> < /
        Alert >
      );
    }



    componentDidMount() {

      /*VKconnect
        .send('VKWebAppGetUserInfo')
        .then(data => {
          // Handling received data
          console.log(data.first_name);
          this.setState({
            fetchedUser: data
          });
          console.log(data);
          console.log(this.state.fetchedUser.id);
          //x = this.state.fetchedUser.id;
        })
        .catch(error => {
          // Handling an error
        })
      .then(() => {  */this.updateSheetValues();//});
    }

    render() {
      const {
        id,
        setPage//,
      //  goBack
      } = this.props;
      //let tempUser = VK.getUserInfo();
      //console.log(VK.getUserInfo());
      //this.setState({fetchedUser: tempUser.data});
      //  console.log(this.state.fetchedUser.first_name)
      return ( <
        Panel id = {
          id
        } >
        <
        PanelHeader  >
          Освобождение  <
          /PanelHeader><
        Group > {
          <
          Div className = "div-center" >
          <
          img src = {
            logo
          }
          alt = "Космический рейс" / >
          <
          /Div>}
          {this.state.loading && <PanelSpinner/>}

{!this.state.loading && <FormLayoutGroup>
          <div style={{display: 'flex'}}>
                 <Button size="l" style={{ marginRight: 10, marginLeft: 15 }} mode =  "primary" stretched  onClick = {
            () => setPage('first_block', 'r1')
          }>Аллея героев</Button>


                      <Button size="l" style={{ marginRight: 15}} mode = {this.state.r1 !== 10  ? "outline" : "primary"} stretched  onClick = {
                 () => this.state.r1 === 10  ? setPage('first_block', 'r2') : this.openPopoutThird()
               }>{this.state.r2 === 10  ? 'Музей космонавтики' : 'Точка 2' }</Button>

</div>


<div style={{display: 'flex'}}>
       <Button size="l" style={{ marginRight: 10, marginLeft: 15 }} mode = {this.state.r2 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r2 === 10  ? setPage('first_block', 'r3') : this.openPopoutThird()
}>{this.state.r3 === 10  ?  'Дом, где жил Исаев':'Точка 3'}</Button>

  <Button size="l" style={{ marginRight: 15}} mode = {this.state.r3 !== 10  ? "outline" : "primary"} stretched  onClick = {
       () => this.state.r3 === 10  ? setPage('first_block', 'r4') : this.openPopoutThird()
     }>{this.state.r4 === 10  ? 'Памятник Люльке' : 'Точка 4'}</Button>

</div>


<div style={{display: 'flex'}}>
       <Button size="l" style={{ marginRight: 10, marginLeft: 15 }} mode = {this.state.r4 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r4 === 10  ? setPage('first_block', 'r5') : this.openPopoutThird()
}>{this.state.r5 === 10  ?  'Ракетный бульвар':'Точка 5'}</Button>

  <Button size="l" style={{ marginRight: 15}} mode = {this.state.r5 !== 10  ? "outline" : "primary"} stretched  onClick = {
       () => this.state.r5 === 10  ? setPage('first_block', 'r6') : this.openPopoutThird()
     }>{this.state.r6 === 10  ? 'Выставочные стенды' : 'Точка 6'}</Button>

</div>



<div style={{display: 'flex'}}>
       <Button size="l" style={{ marginRight: 10, marginLeft: 15 }} mode = {this.state.r6 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r6 === 10  ? setPage('first_block', 'r7') : this.openPopoutThird()
}>{this.state.r7 === 10  ?  'Табличка Кибальчичу':'Точка 7'}</Button>

  <Button size="l" style={{ marginRight: 15}} mode = {this.state.r7 !== 10  ? "outline" : "primary"} stretched  onClick = {
       () => this.state.r7 === 10  ? setPage('first_block', 'r8') : this.openPopoutThird()
     }>{this.state.r8 === 10  ? 'Граффити "Гагарин"' : 'Точка 8'}</Button>

</div>

<div style={{display: 'flex'}}>
       <Button  size="l" style={{ marginRight: 10, marginLeft: 15 }} mode = {this.state.r8 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r8 === 10  ? setPage('first_block', 'r9') : this.openPopoutThird()
}>{this.state.r9 === 10  ?  'Кинотеатр "Космос"':'Точка 9'}</Button>

  <Button size="l" style={{ marginRight: 15}} mode = {this.state.r9 !== 10  ? "outline" : "primary"} stretched  onClick = {
       () => this.state.r9 === 10  ? setPage('first_block', 'r10') : this.openPopoutThird()
     }>{this.state.r10 === 10  ? 'Сквер и дом-музей' : 'Точка 10'}</Button>

</div>

<div style={{display: 'flex'}}>
       <Button  size="l" style={{ marginRight: 10, marginLeft: 15 }} mode = {this.state.r10 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r10 === 10  ? setPage('first_block', 'r11') : this.openPopoutThird()
}>{this.state.r11 === 10  ?  'Доска Раушенбаху':'Точка 11'}</Button>

  <Button size="l" style={{ marginRight: 15}} mode = {this.state.r11 !== 10  ? "outline" : "primary"} stretched  onClick = {
       () => this.state.r11 === 10  ? setPage('first_block', 'r12') : this.openPopoutThird()
     }>{this.state.r12 === 10  ? 'Городок космонавтов' : 'Точка 12'}</Button>

</div>
<div style={{display: 'flex'}}>
       <Button  size="l" style={{ marginRight: 15, marginLeft: 15 }} mode = {this.state.r12 !== 10  ? "outline" : "primary"} stretched  onClick = {
  () => this.state.r12 === 10  ? setPage('first_block', 'r13') : this.openPopoutThird()
}>{this.state.r13 === 10  ?  'Гидрометеостанция':'Точка 13'}</Button>
</div>






                         <Div style={{display: 'flex'}}>
                                <Button size="l" mode = "secondary" stretched style={{ marginRight: 8 }} onClick = {
                           () => setPage('home', 'form')
                         }>Анкета</Button>

                              </Div>
                  <
          Div >
          <
          Button mode = "secondary"
          size = "l"
          stretched = {
            true
          }
          onClick = {
            () => this.openPopoutHelp()
          } > Код команды {global.team_rn} < /Button> < /
          Div ></FormLayoutGroup>} <
          /Group> < /
          Panel >

        );
      }

    }
    //  {withoutEpic && <Div>
    //      <Button mode="secondary" size="l" stretched={true} onClick={() => setPage('modal', 'filters')}>Открыть модальное окно</Button>
    //  </Div>}
    //<Div>
    //    <Button mode="secondary" size="l" stretched={true} onClick={() => this.props.openModal("MODAL_PAGE_BOTS_LIST")}>Открыть
    //        модальную страницу</Button>
    //</Div>
    const mapDispatchToProps = {
      setPage,
      goBack,
      openPopout,
      closePopout,
      openModal
    };

    export default connect(null, mapDispatchToProps)(HomePanelBaseFirstBlock);
