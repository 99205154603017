import React from 'react';
import {
  connect
} from 'react-redux';
//import bridge from '@vkontakte/vk-bridge';

import {
  closePopout,
  goBack,
  openModal,
  openPopout,
  setPage
} from '../../store/router/actions';

//import Telegram from 'telegram-send-message';

import {
  Div,
  Panel,
  Alert,
  Group,
  Input,
  Button, Link,
  PanelHeader//, PanelSpinner
} from "@vkontakte/vkui"
import '../global.js'

import logo from './logo.png';
//import {
//  ACCESS_TOKEN
//} from '../../../App.js';
const SHEET_ID = '1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U'; //TODO remove sheet id and google integration
//const { TelegramClient } = require('messaging-api-telegram');
//const client = TelegramClient.connect('1058147643:AAH8rcL7G9GRkvy2CbFfmEdWCSIFNehvZ6U');
export {
  SHEET_ID
};
//console.log(global.team_rn)

//const { sendMessageFor } = require('simple-telegram-message')
//const sendMessage = sendMessageFor('1058147643:AAH8rcL7G9GRkvy2CbFfmEdWCSIFNehvZ6U', '3191519')


//671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com client idea
//0KCv_KD9POo5rIqnRjTdOjk1 client secret
//AIzaSyDhMYGbNPaNO9VW9LlLlUQFP38k5rvPOds api key
//'ya29.a0Adw1xeVRAWcqYURYhWXW9nafHRayv-t_hXS1q-zxfeQjExazbHxBlu_LO6O0MUx12ov9d_gSKXEgcn6lOP8CjKHKQQ1S-bN6MRbl4AFO60xnjzIDdqmcNlW8uhN6__hTcn_QHrRzkao74W1C_diZ7nL5pat_3MuTp66H';

// get accessToken from telegram [@BotFather](https://telegram.me/BotFather)

//var x = 0;
//var today = new Date();//'rogachevav'
//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
//import * as VK from '../../../js/services/VK';
class HomePanelBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        rn: '',
        showButton: false,
        fetchedUser: null,
        user_mock: 494830811,
        geoData: null,
        new_player: true,
        link: false,
      //  loading: true,
        hit: false
      };
  //  this.openBase = this.openBase.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  //  this.addressItems = [
//{ label: 'Почтовый индекс', name: 'zip' },
//{ label: 'Страна', name: 'country' },
//{ label: 'Город', name: 'city' }
//];

this.onChange = this.onChange.bind(this);
    //    this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
  }
  onChange(e) {
 const { name, value } = e.currentTarget;
 this.setState({ [name]: value });


if (value.length ===6){
 fetch(`https://spacequest.site:82/is_valid_link/${value}`, {
   method: 'GET', // *GET, POST, PUT, DELETE, etc.
   mode: 'cors', // no-cors, *cors,id=6HR2GE same-origin
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
   credentials: 'same-origin', // include, *same-origin, omit
   headers: {
     'Content-Type': 'application/json'
     // 'Content-Type': 'application/x-www-form-urlencoded',
   }})
       .then((response) => {
         return response.json();
       })
       .then((data) => {
         if (data.link === 'true')
         {
           global.team_rn = value;
           localStorage.setItem('team_rn',global.team_rn)
           console.log('set item team_rn in local storage');
         }
         else
         {
           console.log('did not set item team_rn in local storage');
         }

         this.setState({
                     link:  (data.link === 'true'),
   //                  loading: false,
                     hit: data.status
         });
         console.log('succesful is_valid_link hit');
         console.log(data);
         console.log('link test result' + this.state.link);
         if (this.state.hit !== 'old') {
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r1/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r2/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r3/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r4/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r5/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r6/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r7/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r8/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r9/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r10/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r11/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r12/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r13/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r14/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r15/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})
         fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r16/no`, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
         }})






       }

     });}





}
handleChange(event) {
  this.setState({
    value: event.target.value
  });
  //let x = this.state.value;
  //console.log(this.state.value);


}

handleSubmit(event) {
  if (this.state.value === 'келдыш') {
    this.setState({
      show: true
    });
  }

  //alert('A name was submitted: ' + this.state.value);
  event.preventDefault();
}

    takeGeo = () => {

setPage('home', 'form')
  //if
  //    this.updateSheetValues();
    };



    updateSheetValues = () => {
    }




    //      {this.state.geoData &&
    //      <Group title="Результат из VK Connect">
    //        <Div
    //        //	before={this.state.fetchedUser.photo_200 ? <Avatar src={this.state.fetchedUser.photo_200}/> : null}
    //        >
    //          {`${this.state.fetchedUser.first_name} ${this.state.fetchedUser.last_name}`}
    //      работает фетчед юзер/
    //
    //        </Div>
    //      </Group>}





    openPopout() {
      this.props.openPopout( <
        Alert actions = {
          [{
            title: 'Хорошо',
            autoclose: true,
            //action: this.takeGeo
          }]
        }
        onClose = {
          () => this.props.closePopout()
        } >

        <
        p > Код неправильный. Либо перейдите по ссылке из письма от Timepad, либо возьмите правильный код у капитана команды - гулять можно вместе. По любым техническим вопросам работы приложения можно (и нужно) писать в <Link href="https://vk.me/kosmicheskyireis" target="_blank">сообщения сообщества "Космический рейс"</Link>< /p> < /
        Alert >
      );
    }


    openDonate() {
      this.props.openPopout( <
        Alert actions = {
          [{
            title: 'Хорошо',
            autoclose: true,
          }]
        }
        onClose = {
          () => this.props.closePopout()
        } >

        <
        p >Пусть капитан пройдёт по ссылке из письма, которое прислал Timepad. Купить прогулку можно <Link href="https://kosmicheskyireis.timepad.ru/event/1348390/" target="_blank">по этой ссылке на Timepad</Link>. По любым техническим вопросам работы приложения можно (и нужно) писать в <Link href="https://vk.me/kosmicheskyireis" target="_blank">сообщения сообщества "Космический рейс"</Link>  < /p> < /
        Alert >
      );
    }

    TestAnswer1 = () => {
      var res = 0;
      if ((this.state.val1.toUpperCase()  === 'ТЕРЕШКОВА') || ((this.state.val1.toUpperCase()  === 'ВАЛЕНТИНА ТЕРЕШКОВА') )) {
        res = res + 1
      }
      if ((this.state.val2.toUpperCase()  === 'АРМСТРОНГ') || ((this.state.val2.toUpperCase()  === 'НИЛ АРМСТРОНГ') )|| ((this.state.val2.toUpperCase()  === 'НИЛ') ) ){
        res = res + 1
      }
      if ((this.state.val3.toUpperCase()  === 'БЛИН') || ((this.state.val3.toUpperCase()  === 'ПЕРВЫЙ БЛИН КОМОМ') )) {
        res = res + 1
      }
      if ((this.state.val4.toUpperCase()  === 'ЧЕЛНОК') || ((this.state.val4.toUpperCase()  === 'ЧЕЛНОК') )) {
        res = res + 1
      }
      if ((this.state.val5.toUpperCase()  === 'ТИТАН') || ((this.state.val5.toUpperCase()  === 'ТИТАН') )) {
        res = res + 1
      }

          this.setState({
        result: res
      })

      console.log(this.state)


      fetch(`https://spacequest.site:80/set_mark/${this.state.fetchedUser.id}/r2/${this.state.result}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      .then(() => {
      console.log('fasthit'+res )
            this.setState({
        result: res
      })
      })
      .then(() => {
      fetch(`https://spacequest.site:80/set_mark/${this.state.fetchedUser.id}/r2/${this.state.result}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      })
      .then(() => {
      this.setState({
        show1: true
      });
      })
      console.log(this.state)


    };


    componentDidMount() {

      var parsedUrl = new URL(window.location.href)
      //console.log('what id did i get' + parsedUrl.searchParams.get('id'))
      if (parsedUrl.searchParams.get('id') == null)
        {
          if (localStorage.getItem('team_rn') != null)
          {
            global.team_rn = localStorage.getItem('team_rn')
          } else {
            console.log('there is no team_rn in URI and storage')
          }
        } else {
          global.team_rn = parsedUrl.searchParams.get('id');
        }
      if (global.team_rn != null)
      {
        this.setState({
          rn: global.team_rn
        });
      }
     console.log('team_rn from URI is ' + global.team_rn)
     if (global.team_rn != null) {}
          fetch(`https://spacequest.site:82/is_valid_link/${global.team_rn}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            }})
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  if (data.link === 'true')
                  {
                    localStorage.setItem('team_rn',global.team_rn)
                    console.log('set item team_rn in local storage');
                  }
                  else
                  {
                    console.log('did not set item team_rn in local storage');
                  }

                  this.setState({
                              link:  (data.link === 'true'),
            //                  loading: false,
                              hit: data.status
                  });
                  console.log('succesful is_valid_link hit');
                  console.log(data);
                  console.log('link test result' + this.state.link);
                   if (this.state.hit !== 'old')  {
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r1/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r2/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r3/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r4/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r5/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r6/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r7/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r8/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r9/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r10/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r11/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r12/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r13/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r14/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r15/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})
                  fetch(`https://spacequest.site:82/set_mark/${this.state.rn}/r16/no`, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }})






                }

                });





    /*  */


//      VKconnect
//        .send('VKWebAppGetGeodata')
//        .then(data => {
//          // Handling received data
//          console.log(data);
//          this.setState({
//            geoData: data
//          });
//          console.log(this.state.geoData);
//          //console.log(this.state.fetchedUser.last_name);
//        })
//        .catch(error => {
//          console.log(error);
//          this.setState({
//            geoData: {
//              lat: 0,
//              long: 0
//            }
//          });
//          // Handling an error
//        }); //this.setState({fetchedUser: VK.getUserInfo().data})
    }

    render() {
       const {rn, link, hit} = this.state;
      const {
        id,
        setPage//,
  //      goBack
      } = this.props;
      //let tempUser = VK.getUserInfo();
      //console.log(VK.getUserInfo());
      //this.setState({fetchedUser: tempUser.data});
      //  console.log(this.state.fetchedUser.first_name)
    //  <!--  {!this.state.loading && <PanelSpinner/>} -->

      return ( <
        Panel id = {
          id
        } >
        <
        PanelHeader style={{display: 'flex', justifyContent: 'center'}} > Прогулка-квест < /PanelHeader>

         <
        Group > {
          <
          Div className = "div-center" >
          <
          img src = {
            logo
          }
          alt = "Космический рейс" / >
          <
          /Div>}


          <Div>
          <
          Input type = "text"
          name="rn"
          value={rn}
          onChange={this.onChange}
        placeholder = "Введите код команды"
 align="center"
        status={(rn.length >= 6) ? 'valid' : 'error'}
        autoComplete = "off" / >
                 </Div>
<Div style={{display: 'flex'}}>

                   <Button size="l" stretched  onClick = {
              () => (hit === 'old' ? (link ? setPage('first_block','base_first_block') : this.openDonate()) : (link ? setPage('home', 'prolog') : this.openDonate()))
            }> Вход</Button>
</Div>


          {!this.state.new_player && <Div style={{display: 'flex'}}>
                 <Button size="l" stretched style={{ marginRight: 8 }} onClick = {
            () => setPage('intro', 'base')
          }>Начать играть</Button>
               </Div>}



       <Div  style={{display: 'flex'}}>
          <
          Button mode = "secondary"
          size = "l"
          stretched
          onClick = {
            () => this.openDonate()
          } > Где получить код? < /Button>


           < /
          Div > <
          /Group> < /
          Panel >
        );
      }

    }
    //  {withoutEpic && <Div>
    //      <Button mode="secondary" size="l" stretched={true} onClick={() => setPage('modal', 'filters')}>Открыть модальное окно</Button>
    //  </Div>}
    //<Div>
    //    <Button mode="secondary" size="l" stretched={true} onClick={() => this.props.openModal("MODAL_PAGE_BOTS_LIST")}>Открыть
    //        модальную страницу</Button>
    //</Div>
    const mapDispatchToProps = {
      setPage,
      goBack,
      openPopout,
      closePopout,
      openModal
    };

    export default connect(null, mapDispatchToProps)(HomePanelBase);
