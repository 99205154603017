import React from 'react';
//import YouTube from 'react-youtube';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from "redux";
import Icon16Clear from '@vkontakte/icons/dist/16/clear';
//import {
//  Link
//} from '@vkontakte/vkui';
//import VKconnect from '@vkontakte/vk-connect';
//import { gapi } from 'gapi-script';
import {
  Input,
  Div,
  Snackbar,
  Avatar,
  //  List,
  Panel,
//  Group,
  Button,
  PanelHeader,
  PanelHeaderBack,
  //Header,
  FormLayout,
  //FormLayoutGroup, Select, Radio, Textarea, Checkbox,
  //Cell,
  //InfoRow,
//  Gallery
} from "@vkontakte/vkui";
import {
  goBack,
  openPopout,
  closePopout,
  openModal, setPage
} from "../../store/router/actions";
////import * as VK from '../../services/VK';
//import img from './p5.jpg';
import './Persik.css'

//import {
//  ACCESS_TOKEN
//} from '../../../App.js';

//var x = 0;
//var today = new Date();
//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();




const orangeBackground = {
  backgroundImage: 'linear-gradient(135deg, #ffb73d, #ffa000)'
};
//101064757234545608916
///0KCv_KD9POo5rIqnRjTdOjk1 client secret
//  const SPREADSHEET_ID = '1hHk8rtvIHexeXaKaa-PA55AyRlxAeUJZRQ-rhK4q47U'; //+++from the URL of your blank Google Sheet
//  const CLIENT_ID = '45826356530-fcnhlcetibkv2eb80fvl5tmjko88vn18.apps.googleusercontent.com';//'671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com';// '671384565159-2sno2gq9j1qsthbcrl1dkt47jt2m9gjv.apps.googleusercontent.com'; //+++from https://console.developers.google.com/apis/credentials
//  const API_KEY = 'AIzaSyAA1d2jaIB0wsxmPKo7qQ-GjbK1z7a1Qno';// 'AIzaSyDhMYGbNPaNO9VW9LlLlUQFP38k5rvPOds'; //+++https://console.developers.google.com/apis/credentials
//AIzaSyCt1WKxstmty-Y72dO7QF-5OyES87khvpM
//
//  const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';


class FirstPanelR8 extends React.Component {


    constructor(props) {
      super(props);

      this.state = {
        val1: '',
        val2: '',
        val3: '',
              val4: '',
        val5: '',
        val6: '',
        val7: '',
        val8: '',
        val9: '',
        val10: '',
        value: '',
        value2: '',
        show1: false,
        show2: false,
        show3: false,
        fetchedUser: null,
        geoData: null,
        showError: false,
        snackbar: null,
        email: '',
      purpose: ''
      };
      this.openBase = this.openBase.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.addressItems = [
  { label: 'Почтовый индекс', name: 'zip' },
  { label: 'Страна', name: 'country' },
  { label: 'Город', name: 'city' }
];

this.onChange = this.onChange.bind(this);
      //    this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
    }
    onChange(e) {
   const { name, value } = e.currentTarget;
   this.setState({ [name]: value });
 }


    handleChange(event) {
      this.setState({
        value: event.target.value
      });
      //let x = this.state.value;
      //console.log(this.state.value);

    }

    TestAnswer1 = () => {
      var res = 0;
      if (this.state.val1.toUpperCase()  === 'ПОЕХАЛИ')  {
        res = res + 10
      }
      if (res === 10){
          this.setState({
        result: res
      })

      console.log(this.state)

      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r8/${this.state.result}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      .then(() => {
      console.log('fasthit'+res )
            this.setState({
        result: res
      })
      })
      .then(() => {
      fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r8/${this.state.result}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
      })
      .then(() => {
      this.setState({
        show1: true
      });
      })
      console.log(this.state)
  }
    };

    TestAnswer3 = () => {
      if ((this.state.value.toUpperCase() === 'катюша'.toUpperCase()) && this.state.show1) {
        this.setState({
          show2: true,
          value: ''
        });
      } else {
        this.openBase()
      }
    };

    handleSubmit(event) {
      if (this.state.value === 'келдыш') {
        this.setState({
          show: true
        });
      }

      //alert('A name was submitted: ' + this.state.value);
      event.preventDefault();
    }
    //    state = {
    //      show: false,
    //  riddle: '',
    //        groups: {
    //            admined: [],
    //            other: [],
    //        },
    //        loading: true,
    //        errorGetAuthToken: false
    //    };


    testGeo = () => {
      console.log('geo is taken')


    /*  VKconnect
        .send('VKWebAppGetGeodata')
        .then(data => {
          // Handling received data
          console.log(data);
          this.setState({
            geoData: data,
            show3: true
          });
          console.log(this.state.geoData);
          //console.log(this.state.fetchedUser.last_name);
        })
        .catch(error => {
          this.setState({
            showError: true
          });
          console.log(error);
          // Handling an error
        }); //this.setState({fetchedUser: VK.getUserInfo().data})
      this.setState({
        showError: true
      });
      */
      this.setState({
        showError: true
      });
    };



    componentDidMount() {
       this.updateSheetValues();

      //this.setState({fetchedUser: VK.getUserInfo().data})
      //  this.openBase()
      //  this.handleClientLoad();
      //console.log(ACCESS_TOKEN);
      //  this.updateSheetValues();
      //console.log(this.props)
    }


    updateSheetValues = () => {
      fetch(`https://spacequest.site:82/get_marks/${global.team_rn}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
      }})
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //if
          console.log(data.user_marks.r8);
          if ( !(data.user_marks.r8 === 'no') ){
            this.setState({
            result: data.user_marks.r8,
            show1: true
            });
          }
          console.log("solved already");
          console.log(data);
          //this.setState({
          //            com_name:  data.message
          //});
        })
        .catch(error => {
          fetch(`https://spacequest.site:82/set_mark/${global.team_rn}/r8/no`, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
          }})
        })
    };


    openBase() {
      if (this.state.snackbar) return;
      this.setState({
        snackbar:
          <
          Snackbar
        layout = "vertical"
        onClose = {
          () => this.setState({
            snackbar: null
          })
        }
        before = {
          <
          Avatar size = {
            24
          }
          style = {
            orangeBackground
          } > < Icon16Clear fill = "#fff"
          width = {
            14
          }
          height = {
            14
          }
          /></Avatar >
        } >
        Ответ неверный <
        /Snackbar>
      });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
      //if (this.state.riddle.equals('asd')) {this.setState({show: true})}

      //      console.log(this.state.riddle)
    }

    handleInput = (e) => {
      let value = e.currentTarget.value;

      if (e.currentTarget.type === 'checkbox') {
        value = e.currentTarget.checked;
      }

      this.setState({
        inputData: {
          ...this.state.inputData,
          [e.currentTarget.name]: value
        }
      })
    };
    //  {this.state.loading && <PanelSpinner/>}
    //  {!this.state.loading && this.state.errorGetAuthToken && <Group>
    //      <Div>Возникла ошибка при получении данных, возможно Вы не выдали права на список групп.</Div>
    //      <Div>
    //          <Button size="l" stretched={true} onClick={() => this.getAuthToken()}>Запросить
    //              повторно</Button>
    //      </Div>
    //  </Group>}
    //https://yadi.sk/d/8zO5XkjW_sbQtw -- ссылка на картинки
    render() {
       const {  val1,  } = this.state;
      const {
        id,
        goBack,
  //      setPage
      } = this.props;
//      const opts = {
//        height: '240',
//        width: '400',
//        playerVars: { // https://developers.google.com/youtube/player_parameters
//          autoplay: 0
//        }
  //    };

      return ( <
        Panel id = {
          id
        } >
        <
        PanelHeader left = {
          <
          PanelHeaderBack onClick = {
            () => goBack()
          }
          />} >
          Граффити "Гагарин"<
          /PanelHeader>
          <YMaps>
             <Div style={{display: 'flex', justifyContent: 'center'}}>
             <Map
             defaultState={{
               center: [55.81718, 37.63717],
               zoom: 16,
               controls: ['zoomControl', 'fullscreenControl'],
             }}
             modules={['control.ZoomControl', 'control.FullscreenControl']}
           >
             <Placemark defaultGeometry={[55.81718, 37.63717]} />
           </Map>
             </Div>
          </YMaps>
          {!this.state.show1 &&        <FormLayout>

                  <Div >
  В 160 метрах на северо-запад стоит строение 1а, отправляйтесь к нему.
  <br/><br/>
  Для получения следующего задания напишите известный глагол, который произнёс изображенный на этом строении человек.
            </Div>


                    <Div>
                    <
                    Input type = "text"
                    name="val1"
                    value={val1}
                    onChange={this.onChange}
                  placeholder = "Что было сказано?"

                  status={(val1.toUpperCase()  === 'ПОЕХАЛИ')  ? 'valid' : 'error'}
                  autoComplete = "off" / >
                           </Div>



                              <Button size="xl" onClick = {
                         () => this.TestAnswer1()}> Проверить</Button>

                            </FormLayout>}


                            {this.state.show1 &&        <FormLayout>


                              <Div >
                              В 160 метрах на северо-запад стоит строение 1а, отправляйтесь к нему.
                                              </Div>

                    <Div >
                              Поехали дальше!</Div>





                                                <Button size="xl" onClick = {
                                           () => {goBack();} }> К следующей точке</Button>

                                              </FormLayout>}




           <
          /Panel>
        );
      }
      _onReady(event) {
        // access to player in all event handlers via event.target
        //  event.target.pauseVideo();
      }
    }


    function mapDispatchToProps(dispatch) {
      return {
        dispatch,
        ...bindActionCreators({
          goBack,
          openPopout,
          closePopout,
          openModal,
          setPage
        }, dispatch)
      }
    }

    const mapStateToProps = (state) => {
      console.log(state)
      return {
        show1: state.show1,
        show2: state.show2,

        accessToken: state.vkui.accessToken
      };
    };

    export default connect(mapStateToProps, mapDispatchToProps)(FirstPanelR8);
